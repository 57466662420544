import React, { useState } from 'react';
import { useTranslateMultipleText } from '../../../../services/google/translate';
import { TextTooltip } from '../../../../components/ToolTip/TextTooltip';
import { ReadMore } from '../../../../components/Readmore';
import { TranslateButton } from '../../../../components/Atoms/TranslateButton';
import { CoordinationDataType, SingleNetworkProps } from './coordinationDataTypes';
import { TranslatedFromLanguage } from '../../../../utils/TranslatedFromLanguage';

type SummaryTextProps = {
    item?: CoordinationDataType
    network?: SingleNetworkProps
    classnames?: string
}

export const NetworkText = ({ item, network, classnames }: SummaryTextProps) => {
    const [showLanguages, setShowLanguages] = useState(false);
    const translation = (e: MouseEvent) => {
        e.stopPropagation();
        e.preventDefault();
        getTranslation();
        setShowLanguages(!showLanguages);
    };
    console.error('network', network);
    const { getTranslation, data: translated, loading } = useTranslateMultipleText({
        text: network?.text,
    });
    const translatedText = translated.length ? translated[0].translations[0] : network?.text;
    return (
        <div className={`d-flex align-items-top ${classnames}`}>
            <p className="mr-3">
                {showLanguages && !loading && (
                    <TranslatedFromLanguage detectedLangs={network?.detectedLangs} />
                )}
                {((item?.coordinationType === 'copost')
            || (item?.coordinationType === 'corepost')) && translatedText.length > 400
                    ? <ReadMore text={translatedText} limit={400} className="mb-2" />
                    : <span className="mb-2">{translatedText}</span>}
            </p>
            <TextTooltip placement="bottom"
                title="Translate"
                id={`content-${network?.docId}-translate`}
                withIcon
            >
                <TranslateButton className="mr-2 ml-n1"
                    onClick={translation}
                    detectedLangs={network?.detectedLangs}
                    loading={loading}
                    showText={false}
                />
            </TextTooltip>
        </div>
    );
};
