import React, { useEffect, useState, Suspense, lazy } from 'react';
import {
    Switch,
    Route,
    Redirect,
    useRouteMatch,
    useLocation
} from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { BreadCrumbs } from '../../components/BreadCrumbs';
import { capitalizeFirstLetter } from '../../utils/text';
import { GET_ROOM_OVERVIEW, GET_USER, UPDATE_SITUATION_ROOM_LAST_VIEWED } from '../../graphql';
import { Loading } from '../../components/Loading';
import { NotFound } from '../Error/404';
import { TopBar } from './TopBar';
import { CreateReport } from './Reports/CreateReport';
import { useUser } from '../../context/user/UserContext';
import { getRoom, refetchRoom } from '../../utils/variables';
import { minimalRoomLayout, routes } from '../../routes';
import { Menu } from './Menu';
import { UnarchiveModal } from '../SituationRooms/UnarchiveModal';
import { Overview } from './Overview';
import { Threats } from './Threats';
import { Threat } from './Threats/Threat';
import { CustomNarrativePage } from './CustomNarratives/Overview/CustomNarrativeDetails';
import { FactChecksContainer } from './FactChecks';
import { FactDetails } from './FactChecks/FactDetails';
import { Settings } from './SettingsNew';
import { SavedReports } from './Reports/SavedReports';
import { WatchList } from './Watchlist';
import { CustomNarratives } from './CustomNarratives';
import { MatchesPage } from './Overview/Matches/MatchesPage';
import { NarrativeThemeDetails } from './AIClustering/DetailsView/NarrativeThemeDetails';
import { NarrativeDetails } from './AIClustering/DetailsView/NarrativeDetails';
import { Comparision } from './AIClustering/Comparision';
import { useCurrentRoute } from '../../utils/useCurrentRoute';
import { AIClustering } from './AIClustering';
import { NetworksPage } from './Networks';
import { ManageSavedItems } from '../../components/NewFilters/ManageSavedItems';
import { CoordinatedNetworks } from './CoordinatedNetworks';

const LocationsMap = lazy(() => import('./LocationsMap').then((module) => ({
    default: module.LocationsMap
})));

export const SituationRoom = () => {
    const [hasCompleted, setHasCompleted] = useState(false);
    const { user, instance, notAllowed } = useUser();
    const match = useRouteMatch();
    const currentRoomLink = match.path.replace(':situation', match.params.situation);
    const [updateLastViewed] = useMutation(UPDATE_SITUATION_ROOM_LAST_VIEWED, {
        refetchQueries: [{ query: GET_USER, variables: { uid: user.uid } }]
    });

    const { data, error, refetch, loading } = useQuery(GET_ROOM_OVERVIEW, {
        variables: { id: match.params.situation },
        onCompleted: ({ situationRoom }) => {
            getRoom(situationRoom);
            refetchRoom(refetch);
            setHasCompleted(true);
            updateLastViewed({
                variables: {
                    situationRoomId: situationRoom.id,
                    lastViewed: new Date().toString(),
                    uid: user.uid
                }
            });
        },
        fetchPolicy: 'cache-and-network'
    });
    const [bread, setBread] = useState([
        { title: 'Situation Rooms', href: '/situation-rooms' },
        { title: data ? data.situationRoom.project_name : match.params.situation, href: `/situation-rooms/${match.params.situation}` }
    ]);

    const location = useLocation();
    useEffect(() => {
        const currentPath = location.pathname.replace(currentRoomLink, '');
        const allBread = [
            { title: 'Situation Rooms', href: '/situation-rooms' },
            { title: data ? data.situationRoom.project_name : match.params.situation, href: `/situation-rooms/${match.params.situation}` }
        ];
        if (currentPath) {
            const pieces = currentPath.split('/').filter(Boolean);
            for (let i = 0; i < pieces.length; i++) {
                const prevUrl = allBread[allBread.length - 1].href;
                if (pieces[i] === 'custom-narrative') {
                    allBread.push({
                        title: 'Custom Narrative',
                        href: `${prevUrl}/custom-narratives?true=custom-narratives`,
                    });
                }
                else if (pieces[i] === 'settings') {
                    allBread.push({
                        title: 'Room Settings',
                        href: `${prevUrl}/${pieces[i]}`,
                    });
                }
                else {
                    allBread.push({
                        title: capitalizeFirstLetter(pieces[i]),
                        href: `${prevUrl}/${pieces[i]}`,
                    });
                }
            }
        }
        setBread(allBread);
    }, [location, currentRoomLink, match.params.situation, data]);

    const currentRoute = useCurrentRoute();

    if (data?.situationRoom && data?.situationRoom?.archived) {
        return <Archived room={data?.situationRoom} />;
    }
    const breadcrumbs = <BreadCrumbs items={bread} />;
    if (error) {
        return <Redirect to="/" />;
    }

    if (loading || !data || !hasCompleted) {
        return <Loading position="150px" gif />;
    }
    const currentPage = bread[2] ? bread[2].title : '';

    const minimalLayout = currentRoute && minimalRoomLayout.includes(currentRoute);

    const isOverview = currentPage === 'Overview' && bread.length === 3;

    return (
        <Menu currentRoomLink={currentRoomLink}
            instanceId={data.situationRoom.instance?.id || instance}
            disabled={minimalLayout}
        >
            <div className={`pt-${minimalLayout ? 0 : 2} position-relative h-100`}>
                {!minimalLayout && <TopBar currentPage={currentPage} isOverview={isOverview} />}
                <Suspense fallback={<Loading relative height={500} />}>
                    <Switch>
                        <Route exact path={routes.situationRoom.base}>
                            <Redirect to={`${currentRoomLink}/overview`} />
                        </Route>
                        <Route path={routes.situationRoom.overview} exact>
                            <Overview bread={breadcrumbs}
                                data={data}
                            />
                        </Route>
                        <Route path={routes.situationRoom.threats} exact>
                            <Threats />
                        </Route>
                        <Route path={routes.situationRoom.threat} exact>
                            <Threat />
                        </Route>
                        <Route path={routes.situationRoom.match} exact>
                            <Threat />
                        </Route>
                        <Route path={routes.situationRoom.customNarrative} exact>
                            <CustomNarrativePage project={match.params.situation}
                                projectId={data.situationRoom.project_id || match.params.situation}
                                match={match}
                                bread={breadcrumbs}
                            />
                        </Route>
                        <Route path={routes.situationRoom.factChecks} exact>
                            <FactChecksContainer bread={breadcrumbs}
                                project={match.params.situation}
                                roomDetails={data}
                                user={user}
                            />
                        </Route>
                        <Route path={routes.situationRoom.factCheck} exact>
                            <FactDetails user={user} room={data.situationRoom} />
                        </Route>
                        {!notAllowed.includes('roomSettings') && (
                            <Route path={routes.situationRoom.settings} exact>
                                <Settings bread={breadcrumbs} />
                            </Route>
                        )}
                        {!notAllowed.includes('createReport') && (
                            <Route path={routes.situationRoom.reportsCreate} exact>
                                <CreateReport bread={breadcrumbs} data={data.situationRoom} />
                            </Route>
                        )}
                        <Route path={routes.situationRoom.reports} exact>
                            <Redirect to={routes.situationRoom.reportsCreate} />
                        </Route>
                        {!notAllowed.includes('savedReports') && (
                            <Route path={routes.situationRoom.reportsSaved} exact>
                                <SavedReports />
                            </Route>
                        )}
                        <Route path={routes.situationRoom.watchlist} exact>
                            <WatchList data={data.situationRoom} />
                        </Route>
                        <Route path={routes.situationRoom.customNarratives}>
                            <CustomNarratives room={data.situationRoom} />
                        </Route>
                        <Route path={routes.situationRoom.aiClustering} exact>
                            <AIClustering room={data.situationRoom} />
                        </Route>
                        <Route path={routes.situationRoom.aiNarrativesThemeDetails} exact>
                            <NarrativeThemeDetails />
                        </Route>
                        <Route path={routes.situationRoom.aiNarrativesDetails} exact>
                            <NarrativeDetails />
                        </Route>
                        <Route path={routes.situationRoom.aiNarrativeComparision} exact>
                            <Comparision />
                        </Route>
                        <Route path={routes.situationRoom.aiNarrativeThemeComparision} exact>
                            <Comparision />
                        </Route>
                        <Route path={routes.situationRoom.matches} exact>
                            <MatchesPage />
                        </Route>
                        <Route path={routes.situationRoom.networks} exact>
                            <NetworksPage />
                        </Route>
                        <Route path={routes.situationRoom.locationsMap} exact>
                            <LocationsMap />
                        </Route>
                        <Route path={routes.situationRoom.manageSavedFilters} exact>
                            <ManageSavedItems />
                        </Route>
                        <Route path={routes.situationRoom.manageSavedBoolean} exact>
                            <ManageSavedItems />
                        </Route>
                        <Route path={routes.situationRoom.coordinatedNetworks} exact>
                            <CoordinatedNetworks />
                        </Route>
                        <Route><NotFound small /></Route>
                    </Switch>
                </Suspense>
            </div>
        </Menu>
    );
};

const Archived = ({ room }) => (
    <div className="content-page mt-0 d-flex align-items-center justify-content-center">
        <UnarchiveModal id={room.id}
            closeModal={() =>
                window.location.replace(`${window.location.origin}/situation-rooms/${room.id}/overview`)}
        />
    </div>
);
