import { gql as gql2 } from '@apollo/client';
import { gql } from '../generated';
import { queryFragments, situationRoomFragments, situationRoomsFragments } from './Fragments';

/*
-------------------------------
Queries
-------------------------------
*/

// Current User
export const GET_USER = gql2`
    query user($uid: String!, $truncateName: Int) {
        user(uid: $uid) {
            id
            uid
            displayName(truncate: $truncateName)
            firstName
            lastName
            email
            photoURL
            role
            instance {
                id
                clientName
                account {
                    accountManager
                }
                plan {
                    core {
                        overview
                        threats
                        logicallyFactChecks
                        narratives
                        aiClustering
                        threatSignals
                        locationMentions
                        trackedActors
                    }
                    integrations {
                        investigations
                        identifiedActorEnrichments
                        CSV
                        reports
                        watchlist
                    }
                    others {
                        contentActions
                        dateInLocalTimeZone
                        betaFilters
                        labels
                        aiNarrativeCoordinates
                        aiNarrativeComparisons
                        alerts
                        newEtl
                        contentCoordination
                        narrativesCoordination
                        smartFilters
                    }
                    disabledThreatTypes
                }
            }
            status
            lastSeen
            createdAt
            bio
            situationRoomViewList{
                situationRoomid
                lastViewed
            }
            jobDescription
            preferences {
                cookies
                marketingEmails
                productEmails
                darkmode
            }
            welcomeMessage
        }
    }
`;

// Current Instance
export const GET_INSTANCE = gql2`
    query getInstance($id: ID!) {
        instance(where: { id: $id }) {
            id
            clientName
            showClientNameInUI
            account {
                contactName
                role
                email
                phoneNumber
                location
                accountManager
                notes
                contractLink
            }
            tier
            plan {
                isArchived
                isTrial
                isPriorityX
                isPriorityMeta
                restrictedTeamRole
                validFrom
                validUntil
                tokens
                requestedTokens
                tokensRequestedBy {
                    email
                    displayName
                }
                userLimit
                situationroomLimit
                disabledThreatTypes
                core {
                    overview
                    threats
                    logicallyFactChecks
                    narratives
                    aiClustering
                    networks
                    threatSignals
                    locationMentions
                    automaticRefresh
                    trackedActors
                    coordinatedNetworks
                }
                integrations {
                    investigations
                    identifiedActorEnrichments
                    CSV
                    reports
                    watchlist
                }
                others {
                    contentActions
                    dateInLocalTimeZone
                    betaFilters
                    labels
                    aiNarrativeCoordinates
                    aiNarrativeComparisons
                    alerts
                    newEtl
                    contentCoordination
                    narrativesCoordination
                    smartFilters
                }
                sources {
                    faceBook
                    twitter
                    newsArticles
                    forums
                }
                disabledThreatTypes
            }
        }
    }
`;

// Current Situation Room Overview
export const GET_ROOM_OVERVIEW = gql2`
    query situationRoom($id: ID) {
        situationRoom(id: $id) {
            ...SituationRoomMetadata
            instance {
                id
                account {
                    accountManager
                }
                plan {
                    core {
                        overview
                        threats
                        logicallyFactChecks
                        narratives
                        threatSignals
                    }
                    integrations {
                        investigations
                        identifiedActorEnrichments
                        CSV
                        reports
                        watchlist
                    }
                    others {
                        contentActions
                        dateInLocalTimeZone
                        betaFilters
                        labels
                        aiNarrativeCoordinates
                        aiNarrativeComparisons
                        alerts
                        newEtl
                        contentCoordination
                        narrativesCoordination
                        smartFilters
                    }
                    disabledThreatTypes
                }
            }
            createdBy {
                ...SituationRoomCreatedBy
            }
            updatedBy {
                displayName
            }
            users {
                ...SituationRoomUser
            }
            query {
                ...FullQuery
            }
            draftId
        }
    }

    ${situationRoomFragments}
    ${queryFragments}
`;

// Get Situation room draft by id
export const GET_ROOM_DRAFT = gql2`
    query situationRoomDraft($id: ID) {
        situationRoomDraft(id: $id) {
            ...SituationRoomMetadata
            instance {
                id
            }
            createdBy {
                ...SituationRoomCreatedBy
            }
            users {
                ...SituationRoomUser
            }
            query {
                ...FullQuery
            }
            situationRoomId
        }
    }
    ${situationRoomFragments}
    ${queryFragments}
`;

// Get Situation rooms by Instance
export const GET_SITUATION_ROOMS = gql2`
    query situationRooms(
        $instance: ID!,
        $user: ID,
        $status: Statuses,
        $limit: Int,
        $skip: Int,
        $sort: SortInput,
        $id: [ID],
        $searchValue: String
    ) {
        situationRooms(
            instance: $instance,
            user: $user,
            status: $status,
            limit: $limit,
            skip: $skip,
            sort: $sort,
            id: $id,
            searchValue: $searchValue
        ) {
            total
            data {
                ...SituationRoomsMetadata
                investigationsCount
                createdBy {
                    ...SituationRoomsCreatedBy
                }
                draftId
            }
        }
    }
    ${situationRoomsFragments}
`;

export const GET_SITUATION_ROOMS_NAMES = gql2`
    query getSituationRoomNames($instance: ID!, $limit: Int) {
        situationRooms(instance: $instance, limit: $limit) {
            data {
                project_name
                deleted
            }
        }
    }
`;
// Get Situation room drafts by Instance
export const GET_SITUATION_ROOM_DRAFTS = gql2`
    query situationRoomDrafts($instance: ID!, $user: ID) {
        situationRoomDrafts(instance: $instance, user: $user) {
            ...SituationRoomsMetadata
            investigationsCount
            createdBy {
                ...SituationRoomsCreatedBy
            }
            actuallyCreatedBy {
                ...SituationRoomsCreatedBy
            }
            situationRoomId
        }
    }
    ${situationRoomsFragments}
`;

export const GET_TEAM = gql2`
    query users($instance: ID!, $truncateName: Int) {
        users(instance: $instance) {
            id
            uid
            displayName(truncate: $truncateName)
            firstName
            lastName
            email
            photoURL
            role
            lastSeen
            status
        }
    }
`;

export const GET_ROOM_TEAM = gql2`
    query getRoomTeam($instance: ID!, $situationRoom: ID!) {
        situationRoom(id: $situationRoom) {
            removedUsers
        }
        users(instance: $instance) {
            id
            uid
            displayName
            firstName
            lastName
            email
            photoURL
            role
        }
    }
`;

export const GET_ADMIN_USERS = gql2`
    query adminUsers($where: UserWhereInput) {
        adminUsers(where: $where) {
            id
            uid
            displayName(truncate: 20)
            firstName
            lastName
            email
            photoURL
            role
        }
    }
`;
export const GET_MULTIPLE_TRANSLATION = gql2`
    query translateMultipleText($data: [TranslateInput]) {
        translateMultipleText(data: $data) {
            translations
        }
    }
`;

export const CHECK_USER_EXISTS = gql2`
    query checkUserExists($email: String!){
        checkUserExists(email: $email){
            done
            message
        }
    }
`;

export const GET_ALL_USERS = gql2`
    query allUsers($limit: Int, $skip: Int, $searchValue: String, $instance: [String], $sort: SortInput) {
        allUsers(limit: $limit, skip: $skip, searchValue: $searchValue, instance: $instance, sort: $sort) {
            total
            data {
                id
                uid
                displayName
                firstName
                lastName
                email
                photoURL
                role
                status
                lastSeen
                instance {
                    id
                    clientName
                }
            }
        }
    }
`;

export const GET_WATCHLIST = gql2`
    query getSituationRoomThreatsWatchlist($situationRoom: ID!, $contentIds: [String]) {
        getSituationRoomThreatsWatchlist(situationRoom: $situationRoom, contentIds: $contentIds) {
            content_id
            text
            from
            ctype
            engagement
            extracted_urls
            domain
            display_name
            threat_labels
            locations_detected
            pubdatetime
            url
            whyathreat
            extracts
            sentiment
            threat_level
            lang
            detected_langs
            lang_verbose
            mentions
            hashtags
            entities
            keywords
        }
    }
`;

export const GET_INVESTIGATIONS = gql2`
    query investigations($situationRoom: ID!) {
        investigations(situationRoom: $situationRoom) {
            id
            createdAt
            updatedAt
            status
            summary
            details
            source
            email
            report {
                id
                filename
                url
            }
            contactEmails
            uploadAttachments
        }
    }
`;

export const GET_ALL_INVESTIGATIONS = gql2`
    query allInvestigations($id: ID) {
        allInvestigations(id: $id) {
            id
            createdAt
            updatedAt
            status
            summary
            details
            source
            uploadAttachments
            email
            contactEmails
            note
            furtherInvestigation
            reportCreatedBy
            createdBy {
                uid
                email
                displayName
                firstName
                lastName
                role
            }
            situationRoom {
                id
                project_name
            }
            report {
                id
                filename
                url
            }
        }
    }
`;

export const GET_CUSTOM_NARRATIVES = gql2`
    query getCustomNarratives($situationRoom: ID!, $id: ID, $limit: Int, $skip: Int) {
        getCustomNarratives(situationRoom: $situationRoom, id: $id, limit: $limit, skip: $skip) {
            total
            customNarratives{
                id
                name
                narrativeTitle
                versionId
                notes
                data {
                    id
                    threat
                    boolean
                    keywords
                    query_type
                    dslQuery
                    isSaved
                }
            }       
        }
    }
`;

export const GOOGLE_TRENDS_QUERY = gql2`
    query googleTrendsQuery($keyword: String!) {
        googleTrendsQuery(keyword: $keyword) {
            query
            link
            value
        }
    }
`;
export const GOOGLE_TRENDS_TOPIC = gql2`
    query googleTrendsTopic($keyword: String!) {
        googleTrendsTopic(keyword: $keyword) {
            topic {
                title
            }
        }
    }
`;

export const GET_ALL_SITUATION_ROOMS = gql2`
    query allSituationRooms($limit: Int, $skip: Int, $filter: RoomFilter, $sort: SortInput) {
        allSituationRooms(limit: $limit, skip: $skip, filter: $filter, sort: $sort) {
            total
            data {
                ...SituationRoomsMetadata
                createdBy {
                    ...SituationRoomsCreatedBy
                }
                actuallyCreatedBy {
                    ...SituationRoomsCreatedBy
                }
                instance {
                    id
                    clientName
                }
                query {
                    ...FullQuery
                }
            }
        }
    }
    ${situationRoomsFragments}
    ${queryFragments}
`;

export const GET_CTYPES = gql2`
    query getCtypes {
        getCtypes {
            id
            key
            name
            color
            FontFamily
            nodeColor
            icon
            disableEngagement
        }
    }
`;

export const GET_CTYPE = gql2`
    query getCtype($id: ID!) {
        getCtype(id: $id) {
            id
            key
            name
            color
            FontFamily
            nodeColor
            icon
            disableEngagement
        }
    }
`;

export const GET_SUGGESTIONS = gql2`
    query getSuggestions ($query: String){
        getSuggestions (query: $query) {
            data {
                original
                name
                url
                description
                type
                image
                score
              }
        }
    }
`;

export const GET_CLAIMS = gql2`
    query getClaims($userId: String, $projectId: String) {
    getClaims(userId: $userId, projectId: $projectId) {
       entries {
         claim_details {
           claims_docs {
             _id
            fact_checked
            project_id
            body
            claim_source_url
            status
            claim_detection
            username
            first_name
            last_name
            rejected
            message
            published_date
            claim_source
            type
            claim_verified_ids
            conclusion
            verdict
            editor_reference_urls{
                source
                offensive_content
                access_date
                source_name
                published_date
                url
                }
            claim_ids
            conclusion_summary
            user_id
            lang_code
            source
            created_at
            title
            content
            moderated
            location
           }
         }
       }
    }
  }
`;

export const GET_CLAIM = gql2`
    query getClaim($userId: String, $claimId: String, $projectId: String) {
    getClaim(userId: $userId, claimId: $claimId, projectId: $projectId) {
       entries {
         claim_details {
           claims_docs {
             _id
            fact_checked
            project_id
            body
            claim_source_url
            status
            claim_detection
            rejected
            username
            first_name
            last_name
            message
            published_date
            claim_source
            type
            claim_verified_ids
            claim_ids
            user_id
            lang_code
            source
            created_at
            title
            content
            moderated
            location
            conclusion
            verdict
            conclusion_summary
            editor_reference_urls{
            source
            offensive_content
            access_date
            source_name
            published_date
            url
            }
           }
         }
       }
    }
  }
`;

export const GET_PASSWORD_RESET = gql2`
    query getPasswordReset($code: String!){
        getPasswordReset(code: $code){
            email
            expiryDate
        }
    }
`;

export const GET_REMOVED_CONTENT = gql2`
    query getRemovedContent($projectId: String, $searchString: String, $offset: Int, $type: String, $limit: Int){
        getRemovedContent(projectId: $projectId, searchString: $searchString, offset: $offset, type: $type, limit: $limit){
            data
            total
        }
    }
`;

export const GET_LOCATIONS = gql2`
    query getLocations($threats: Boolean, $projectId: String!, $size: Int, $filters: ContentFilters, $dslQuery: JSON) {
        getLocations(threats: $threats, projectId: $projectId, size: $size, filters: $filters, dslQuery: $dslQuery) {
            data {
                location
                count
            }
            total
        }
    }
`;

export const GET_INSTANCES = gql2`
    query instances {
        instances {
            id
            clientName
        }
    }
`;

export const GET_INVESTIGATION_TOKENS = gql2`
    query getInvestigationTokens($instance:ID){
        getInvestigationTokens(instance: $instance){
            id
            instance{
                id
                clientName
            }
            tokens
            totalTokens
            tokensRequested{
                id
                requestedBy{
                    id
                    email
                    displayName
                }
                tokensRequested
            }
        }
    }
`;

export const GET_CLUSTERS_FOR_CHART = gql2`
    query getClustersForChart(
        $projectId: String!
        $search: String
        $filters: ClusterFilters
    ){
        getClusters(
            projectId: $projectId
            search: $search
            filters: $filters
        ){
            clusters{
                id
                duplicatedDocsCount
                sentimentJson{
                    sentiment
                    score
                }
                title
                topActors{
                    actor
                    contentIds
                    ctype
                }
                subClustersMappedId
                volume
                coordinates {
                    x
                    y
                }
                averageSentiment
            }
            total
        }
    }
`;

export const GET_CLUSTERS_COUNT = gql2`
    query getClustersCount($projectId: String!, $filters: ClusterFilters){
        getClustersCount(projectId: $projectId, filters: $filters){
            clusters
            subClusters
        }
    }
`;

export const GET_CLUSTER_TITLES = gql2`
    query getClustersTitles(
        $projectId: String!
        $id: [String]
    ){
        getClusters(
            projectId: $projectId
            id: $id
        ){
            clusters{
                id
                title
            }
        }
    }
`;

export const GET_CLUSTERS_FOR_LIST_VIEW = gql2`
    query getClustersForListView(
        $projectId: String!
        $id: [String]
        $limit: Int
        $skip: Int
        $sort: ClusterSort
        $search: String
        $selectedCluster: String
        $filters: ClusterFilters
        $isWatchlisted: Boolean
        $checkStatus: CheckStatus
    ){
        getClusters(
            projectId: $projectId
            id: $id
            limit: $limit
            skip: $skip
            sort: $sort
            search: $search
            selectedCluster: $selectedCluster
            filters: $filters
            isWatchlisted: $isWatchlisted
            checkStatus: $checkStatus
        ){
            clusters{
                id
                addedDate
                description
                duplicatedDocsCount
                projectId
                sentimentJson{
                    sentiment
                    score
                }
                title
                subClustersMappedId
                status
                volume
                engagement
                isWatching
                removedBy
                updatedDate
                averageSentiment
                cibNetwork{
                    networkId
                    networkType
                }
            }
            total
        }
    }
`;

export const GET_CLUSTERS = gql2(`
    query getClustersPaginated(
        $projectId: String!
        $id: [String]
        $limit: Int
        $skip: Int
        $sort: ClusterSort
        $search: String
        $selectedCluster: String
        $filters: ClusterFilters
        $checkStatus: CheckStatus
    ){
        getClusters(
            projectId: $projectId
            id: $id
            limit: $limit
            skip: $skip
            sort: $sort
            search: $search
            selectedCluster: $selectedCluster
            filters: $filters
            checkStatus: $checkStatus
        ){
            clusters{
                id
                addedDate
                description
                duplicatedDocsCount
                keyphrases{
                    keyword
                    contentIds
                }
                projectId
                sentimentJson{
                    sentiment
                    score
                }
                title
                topActors{
                    actor
                    contentIds
                    ctype
                }
                topSources{
                    source
                    contentIds
                }
                subClustersMappedId
                status
                volume
                volumeOverTime{
                    time
                    count
                }
                engagementOverTime{
                    time
                    count
                }
                engagement
                isWatching
                locationMentions{
                    location
                    contentIds
                }
                averageSentiment
                removedBy
                languages{
                    language
                    contentIds
                }
                urlMentions{
                    url
                    contentIds
                }
                cibNetwork{
                    networkId
                    networkType
                }    
            }
            total
        }
    }
`);

export const GET_SUB_CLUSTERS_FOR_CHART = gql2`
    query getSubClustersForChart($ids: [String]!, $projectId: String!){
        getSubClusters(ids: $ids, projectId: $projectId){
            id
            sentimentJson{
                sentiment
                score
            }
            subClusterTitle
            volume
            duplicatedDocsCount
             topActors{
                actor
            }
        }
    }
`;

export const GET_SUB_CLUSTERS_FOR_LIST = gql2`
    query getSubClustersForList(
        $ids: [String]!, 
        $projectId: String!,
        $limit: Int
        $skip: Int
        $sort: ClusterSort
        $filters: ClusterFilters
    ){
        getSubClusters(
            ids: $ids, 
            projectId: $projectId,
            limit: $limit,
            skip: $skip,
            sort: $sort,
            filters: $filters
        ){
            id
            contentIds{
                id
                duplicateIds
            }
            duplicatedDocsCount
            originatorPostCtype
            originatorPostId
            originatorPostName
            sentimentJson{
                sentiment
                score
            }
            subClusterSummary
            subClusterTitle
            volume
            engagement
            isWatching
            removedBy
            status

        }
    }
`;

export const GET_SUB_CLUSTERS = gql(`
    query getSubClusters(
        $ids: [String]!, 
        $projectId: String!,
        $limit: Int
        $skip: Int
        $sort: ClusterSort,
        $checkStatus: CheckStatus
    ){
        getSubClusters(
            ids: $ids, 
            projectId: $projectId,
            limit: $limit,
            skip: $skip,
            sort: $sort,
            checkStatus: $checkStatus
        ){
            id
            addedDate
            contentIds{
                id
                duplicateIds
            }
            duplicatedDocsCount
            keyphrases{
                keyword
                contentIds
            }
            originatorPostCtype
            originatorPostId
            originatorPostName
            sentimentJson{
                sentiment
                score
            }
            subClusterSummary
            subClusterTitle
            status
            volume
            volumeOverTime{
                date
                count
            }
            engagementOverTime{
                time
                count
            }
            topActors{
                actor
                contentIds
                ctype
            }
            topSources{
                source
                contentIds
            }
            engagement
            isWatching
            locationMentions{
                location
                contentIds
            }
            topClustersMappedId
            averageSentiment
            removedBy
            status
            languages{
                language
                contentIds
            }
            urlMentions{
                url
                contentIds
            }
        }
    }
`);

export const GET_ALL_SUB_CLUSTERS = gql2`
    query getAllSubClusters(
        $projectId: String!,
        $limit: Int
        $skip: Int
        $sort: ClusterSort
        $filters: SubClusterFilters
        $isWatchlisted: Boolean
        $search: String
        $checkStatus: CheckStatus
    ){
        getAllSubClusters(
            projectId: $projectId,
            limit: $limit,
            skip: $skip,
            sort: $sort
            filters: $filters
            isWatchlisted: $isWatchlisted
            search: $search
            checkStatus: $checkStatus
        ){
            subClusters{
                id
                subClusterTitle
                subClusterSummary
                engagement
                volume
                isWatching
                duplicatedDocsCount
                originatorPostId
                originatorPostName
                originatorPostCtype
                topClustersMappedId
                sentimentJson{
                    sentiment
                    score
                }
                removedBy
                updatedDate
                status
                contentIds{
                    id
                    duplicateIds
                }
            }
            total
        }
    }
`;

export const GET_ALERTS = gql2`
    query getAllAlerts(
            $projectId: String
            $limit: Int
            $offset: Int
            $category: Category
            $rule: Int
            $unreadOnly: Boolean
            $archived: Boolean
    ){
        getAlerts(
            projectId: $projectId
            limit: $limit
            offset: $offset
            category: $category
            rule: $rule
            unreadOnly: $unreadOnly
            archived: $archived
        ){
           alerts{
                id
                date
                description
                category
                rule
                projectId
                subcategory
                situationRoom {
                    id
                    name
                }
                read
                archived
            }
            total
            unread
        }
    }
`;

export const GET_CONTENT_COORDINATION = gql(`
    query getCoordinations(
            $projectId: String!
            $docIds: [String]
            $ids: [String]
            $actors: [String]
    ){
        getCoordinations(
            projectId: $projectId
            docIds: $docIds
            ids: $ids
            actors: $actors
        ){
            id
            docIds
            coordinationType
            coordinationSource
            network {
                docId
                pubdatetime
                text
                from
                ctype
                url
                detectedLangs
            }
        }      
    }
`);
