import React, { useState, useRef, useEffect, useCallback } from 'react';
import { ChevronRight } from 'react-feather';
import { UncontrolledTooltip } from 'reactstrap';
import { ResizeObserver } from 'resize-observer';
import { useDispatch, useSelector } from 'react-redux';
import { roundPercentage } from '../../../../../utils/NumberFormat';
import { toId } from '../utils';
import { LoadingState } from './Loading';
import { useSources } from '../../../../../services/Overview';
import { ContentModalButton } from '../../ContentModal/ContentModalButton';
import { color } from '../../../../../utils/getColors';
import { setLayout } from '../../store';

export const SourcesList = () => {
    const type = 'ctype__keyword';
    const { layout } = useSelector((state) => state.overview);
    const [showAll, setShowAll] = useState(false);
    const [maxLabels, setMaxLabels] = useState(5);
    const ref = useRef(null);
    const { data, loading } = useSources({ size: 50 });
    const dispatch = useDispatch();

    const getSize = useCallback(() => {
        if (!ref || !ref.current || !data.length) { return 5; }
        const width = ref.current.offsetWidth - (48 + 86);
        let done = -1; let totalWidth = 0;
        for (let i = 0; i < data.length; i++) {
            const label = data[i].source;
            const itemWidth = (`${label} - ${data[i].count}`.length * 8) + 20;
            if (totalWidth + itemWidth < width) {
                done++;
                totalWidth += itemWidth;
            } else {
                break;
            }
        }
        return done;
    }, [data]);

    useEffect(() => {
        let timer = null;
        const handleResize = () => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                const newSize = getSize();
                if (newSize !== maxLabels && setMaxLabels) {
                    setMaxLabels(newSize);
                }
            }, 300);
        };
        handleResize();
        if (!ref?.current) { return () => clearTimeout(timer); }
        const ro = new ResizeObserver(handleResize);
        ro.observe(ref.current);
        const current = ref?.current;
        return () => {
            clearTimeout(timer);
            if (current) {
                ro.unobserve(current);
            }
        };
    }, [ref, getSize, maxLabels]);

    if (loading) {
        return <LoadingState />;
    }
    if (!data.length) { return null; }

    const handleShowAll = () => {
        if (layout) {
            dispatch(setLayout({
                ...layout,
                data: layout.data.map(item => {
                    if (item.id === 'sourcesList') {
                        let extendBy = item.w <= 16 ? 4 : 3;
                        if (item.w === 24) extendBy = 3;
                        return {
                            ...item,
                            h: showAll ? item.h - extendBy : item.h + extendBy
                        };
                    }
                    return item;
                })
            }));
        }

        setShowAll(!showAll);
    };
    return (
        <div ref={ref} data-testid="horizontal-bar-custom">
            <h3 className="mb-2 mt-0">Sources</h3>
            <SourcesListOverview data={data} />
            <div className="d-flex align-items-center justify-content-stretch flex-wrap">
                {data.map((item, index) => {
                    if (!showAll && index > maxLabels) { return null; }
                    return (
                        <p key={item.key}
                            className="mr-2 mt-2 mb-0 line-height-1"
                            data-testid={`horizontal-bar-custom-${item.key}`}
                        >
                            <ContentModalButton value={[item.key]} type={type} label={item.source}>
                                <span>
                                    {item.source} - {item.count}
                                </span>
                            </ContentModalButton>
                        </p>
                    );
                })}
                {data.length > maxLabels + 1 && (
                    <div role="button"
                        className="d-flex align-items-center mt-2"
                        data-testid="horizontal-bar-custom-see-more"
                        onClick={handleShowAll}
                    >
                        <p className="m-0 text-primary line-height-1">See {showAll ? 'less' : 'more'}</p>
                        <ChevronRight size={18} color={color.blue[500]} />
                    </div>
                )}
            </div>
        </div>
    );
};

const SourcesListOverview = ({ data }) => {
    const [active, setActive] = useState(false);
    const totalValue = data.reduce((acc, item) => acc + item.count, 0);
    return (
        <div className="d-flex align-items-center justify-content-stretch">
            {data.map((item, index) => {
                const percentage = (100 * item.count) / totalValue;
                const highlighted = !active || active === item.key;
                const key = item.key + index;
                return (
                    <React.Fragment key={key}>
                        <div style={{ flexGrow: 1, maxWidth: `${percentage}%`, minWidth: 16, height: 12, borderRadius: 2 }}
                            className={`mr-11 transition bg-${highlighted ? 'primary' : 'primary-hover'}`}
                            onMouseEnter={() => setActive(item.key)}
                            onMouseLeave={() => setActive(false)}
                            role="button"
                            id={`tooltip-sourcesviz-${toId(item.key)}`}
                        />
                        <UncontrolledTooltip target={`tooltip-sourcesviz-${toId(item.key)}`}
                            trigger="hover"
                            innerClassName="line-height-1"
                        >
                            {item.source} <span className="ml-2">{item.count} ({roundPercentage(percentage)}%)</span>
                        </UncontrolledTooltip>
                    </React.Fragment>
                );
            })}
        </div>
    );
};
