import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { UserMongo } from './generated/graphql';

const envCheck = window.location.origin !== 'http://localhost:3000';

if (envCheck) {
    amplitude.init('ba581b9da993e770fac8e44161a1adaf', { autocapture: true });
    const sessionReplayTracking = sessionReplayPlugin({
        sampleRate: 1
    });
    amplitude.add(sessionReplayTracking);
}

export const amplitudeIdentify = (user: UserMongo) => {
    if (!envCheck) return;
    const identifyEvent = new amplitude.Identify();
    amplitude.setUserId(user.email || '');
    identifyEvent.set('uid', user.uid || '');
    identifyEvent.set('email', user.email || '');
    identifyEvent.set('name', user.displayName || '');
    identifyEvent.set('instanceId', user.instance?.id || '');
    identifyEvent.set('clientName', user.instance?.clientName || '');
    amplitude.identify(identifyEvent);
};

export const amplitudeTrack = (event: string, properties?: Record<string, any>) => {
    if (!envCheck) return;
    const eventProperties = {
        ...properties,
        session_id: amplitude.getSessionId()
    };
    amplitude.track(event, eventProperties);
};
