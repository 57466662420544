import React, { useRef } from 'react';
import { Chart } from 'regraph';
import { useDispatch, useSelector } from 'react-redux';
import { useNetworksData } from './useNetworks';
import { Loading } from '../../../../components/Loading';
import { useChartItems } from './chartItems';
import { NetworksToolbar, NetworksToolbarRef } from '../../Networks/NetworksChart/Toolbar';
// import { NetworksTimeline } from './Timeline';
import { incrementChartUpdates, NetworksHistoryStore, NetworksState, setRegraphLoading } from '../../Networks/store';
import {
    handleDragStart,
    useChartChange,
    useChartClick,
    useChartExport,
    useChartZoom,
    useDeleteNodes
} from '../../Networks/NetworksChart/handlers';
import { color } from '../../../../utils/getColors';
import { CleanChartState, MemoizedChart } from '../../Networks/NetworksChart';

export const NetworksChart = () => {
    const dispatch = useDispatch();
    const { regraphLoading, chartKey, chartUpdates } = useSelector((state: { networks: NetworksState }) => state.networks);
    const { chartState, layout, clusterBy } = useSelector((state: NetworksHistoryStore) => state.networksHistory.present);
    // fetch networks data
    const { loading } = useNetworksData();

    // format networks data for Regraph
    const items = useChartItems();

    // refs
    const toolbarRef = useRef<NetworksToolbarRef>(null);
    const chartRef = useRef<Chart>(null);

    // handlers
    useDeleteNodes({ chartRef });
    const { handleChange } = useChartChange();
    const { handleClick } = useChartClick({ items });
    const { chartZoom, onViewChange } = useChartZoom({ chartRef, toolbarRef });
    const { chartExport } = useChartExport({ chartRef });
    const handleUpdateComplete = () => {
        if (regraphLoading && !loading) {
            dispatch(setRegraphLoading(false));
            dispatch(incrementChartUpdates());
        }
    };

    return (
        <div className="flex-1 d-flex flex-column mh-0">
            <CleanChartState />
            <div className="bg-primary-hover position-relative flex-1 mh-0">
                {loading && <Loading message="fetching data..." />}
                {(regraphLoading && !loading) && <Loading background={color.blue['100']} message="loading graph..." />}
                <MemoizedChart items={items}
                    positions={chartState.positions}
                    onUpdateComplete={handleUpdateComplete}
                    onDragStart={handleDragStart}
                    onClick={handleClick}
                    onViewChange={onViewChange}
                    onChange={handleChange}
                    chartRef={chartRef}
                    layout={{ name: layout }}
                    combine={clusterBy ? {
                        properties: [clusterBy],
                        level: 1
                    } : undefined}
                    key={chartKey}
                    chartUpdates={chartUpdates}
                />
                <NetworksToolbar ref={toolbarRef} chartZoom={chartZoom} chartExport={chartExport} />
            </div>
            {/* <NetworksTimeline /> */}
        </div>
    );
};
