import React from 'react';
import { Cpu } from 'react-feather';
import { useQuery } from '@apollo/client';
import { checkGrammer } from '../../../../../utils/text/checkGrammer';
import { WidgetName, WidgetTextTooltip } from '../Summary/WidgetTextTooltip';
import { setParam } from '../../../../../utils/urlParams';
import { useModal } from '../../../../../context/modal/ModalComponent';
import { GET_CONTENT_COORDINATION } from '../../../../../graphql';
import { getRoom } from '../../../../../utils/variables';
import { CoordinatedList } from './CoordinatedList';
import { CoordinatedTopKeowrds } from '../../CoordinatedModals/CoordinatedTopKeowrds';
import { TopActors } from './TopActors';
import { Loading } from '../../../../../components/Loading';
import { NetworksButton } from '../../Utils/NetworksButton';
import { CoordinationDataType } from '../../CoordinatedModals/coordinationDataTypes';

type NetworksDetectedProps = {
    coordinationIds: string[]
}

export const NetworksDetected = ({ coordinationIds }: NetworksDetectedProps) => {
    const room = getRoom();
    const { setModal, closeModal } = useModal();
    const { data, loading } = useQuery(GET_CONTENT_COORDINATION, {
        variables: {
            projectId: room.project_id,
            ids: coordinationIds
        },
        fetchPolicy: 'cache-and-network'
    });
    const coordinations = data?.getCoordinations || [];
    const coPost = coordinations?.filter((item) => item.coordinationType === 'copost');
    const coRePost = coordinations?.filter((item) => item.coordinationType === 'corepost');
    const coHastags = coordinations?.filter((item) => item.coordinationType === 'cohashtag');
    const coSimilarAccount = coordinations?.filter((item) => item.coordinationType === 'similar_account');
    const coPostingPattern = coordinations?.filter((item) => item.coordinationType === 'similar_post_pattern');
    const coHastagsSequence = coordinations?.filter((item) => item.coordinationType === 'cohashtag_sequence');

    const handleSetParam = (name: string, coordinationData: CoordinationDataType[]) => {
        setParam('subTab', name);
        setModal({
            header:
    <div className="d-flex align-items-center">
        <WidgetTextTooltip widgetName={WidgetName.CoordinationDetected} /><Cpu className="ml-1 mb-1" />
    </div>,
            component: <CoordinatedList coordinationData={coordinationData} />,
            size: 'xl',
            onClose: closeModal
        });
    };
    if (loading) {
        return <Loading relative height={400} />;
    }
    return (
        <div className="selected-cluster-height scrollbar-small overflow-y-auto overflow-x-hidden">
            <div className="d-flex align-items-center p-3 justify-content-between">
                <div className="d-flex align-items-center">
                    <h3>
                        <WidgetTextTooltip widgetName={WidgetName.CoordinationTactics} noMargin />
                    </h3>
                    <span className="bg-secondary-alt rounder-4 text-white p-11 ml-1">
                        {coordinations?.length} {checkGrammer('coordination', coordinations.length > 1)}
                    </span>
                </div>
            </div>
            <hr className="m-0" />
            <div className="scrollbar-small overflow-y-auto overflow-x-hidden p-3 bg-verylight">
                <div className="d-flex align-items-center justify-content-between mt-1">
                    <div className="p-2 rounder-4 bg-lightgray w-100 mr-2">
                        <NetworksButton handleSetParam={handleSetParam}
                            network={coPost}
                            widgetName="CoordinatedPosts"
                            type="Coordinated_posts"
                        />
                    </div>
                    <div className="p-2 rounder-4 bg-lightgray w-100 mr-2">
                        <NetworksButton handleSetParam={handleSetParam}
                            network={coRePost}
                            widgetName="CoordinatedReposts"
                            type="Coordinated_reposts"
                        />
                    </div>
                    <div className="p-2 rounder-4 bg-lightgray w-100">
                        <NetworksButton handleSetParam={handleSetParam}
                            network={coHastags}
                            widgetName="CoordinatedHashtags"
                            type="Coordinated_hashtags"
                        />
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-between mt-2">
                    <div className="p-2 rounder-4 bg-lightgray w-100 mr-2">
                        <NetworksButton handleSetParam={handleSetParam}
                            network={coHastagsSequence}
                            widgetName="CoordinatedHashtagSequences"
                            type="Coordinated_posts"
                        />
                    </div>
                    <div className="p-2 rounder-4 bg-lightgray w-100 mr-2">
                        <NetworksButton handleSetParam={handleSetParam}
                            network={coSimilarAccount}
                            widgetName="SimilarAccountHandles"
                            type="Similar_account_handles"
                        />
                    </div>
                    <div className="p-2 rounder-4 bg-lightgray w-100">
                        <NetworksButton handleSetParam={handleSetParam}
                            network={coPostingPattern}
                            widgetName="SimilarPostingPatterns"
                            type="Similar_posting_patterns"
                        />
                    </div>
                </div>
                <TopActors coordinations={coordinations} />
                <CoordinatedTopKeowrds coordinationData={coordinations} />
            </div>
        </div>
    );
};
