import React from 'react';
import { getCoordinatedDateType } from '../../../../utils/coordinatedTypes';
import { DateVisualizer } from '../../../../components/DateVisualizer';
import { IconsAndCount } from './ActorsAndPosts';
import { getPostedByName } from '../../../../utils/getPostedByName';
import { SourceIconLabel } from '../../../../components/SourceIconLabel';
import { getCtype } from '../../../../utils/text';
import { WidgetName, WidgetTextTooltip } from '../SelectedClusterView/Summary/WidgetTextTooltip';
import { SingleNetworkProps } from './coordinationDataTypes';
import { getParam } from '../../../../utils/urlParams';
import { NetworkText } from './NetworkText';

type CoordinatedDetailProps = {
    header?: string;
    selectedCoordination?: string
    singleNetworkData?: SingleNetworkProps[]
    originalPostedByName?: string
    goToActors?: (name: string) => void

}

export const CoordinatedDetail = ({ header = '',
    selectedCoordination, singleNetworkData, originalPostedByName, goToActors }: CoordinatedDetailProps) => {
    const newHeader: string = getParam('proxyTab') ? getParam('proxyTab') || '' : header;
    const selectedNetworkText = newHeader === 'Post_network' ? singleNetworkData?.filter((network: SingleNetworkProps) =>
        network.docId === selectedCoordination)?.[0]?.text : selectedCoordination;
    const getWidgetName = (name: string) => {
        const nameFormatted = name.replace(/_/g, ' ');
        return nameFormatted.replace(/\s+(\w)/g, (_, p1) => p1.toUpperCase()) as keyof typeof WidgetName;
    };
    const endDate = singleNetworkData?.[0].pubdatetime || new Date();
    const startDate = singleNetworkData?.[singleNetworkData.length - 1].pubdatetime ?? new Date();
    const countMap = new Map();
    singleNetworkData?.forEach((item: { from: any }) => {
        countMap.set(item.from, (countMap.get(item.from) || 0) + 1);
    });
    const uniqueActors = Array.from(
        new Map(singleNetworkData?.map((item) => [
            item.from,
            { ...item, totalCount: countMap.get(item.from) } // Adding total count
        ])).values()
    );
    return (
        <>
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                    <h3 className="my-0 mr-1 widget-text-style">
                        <WidgetTextTooltip noMargin
                            widgetName={WidgetName[getWidgetName(newHeader)]}
                        />
                    </h3>
                    <span className="tag" color="dark">{singleNetworkData?.length || 1} posts </span>
                </div>
                <DateVisualizer dateType={getCoordinatedDateType(newHeader)} startDate={startDate} endDate={endDate} />
            </div>
            <div className="bg-lightgray p-2 rounded my-2">
                <h3>Summary</h3>
                <hr />
                <p className="mb-2">{selectedNetworkText}</p>
                <div className="d-flex gap-2 mt-2">
                    <IconsAndCount label="Actors" count={uniqueActors.length || 0} />
                    <IconsAndCount label="Posts" count={singleNetworkData?.length || 0} />
                </div>
            </div>
            <div className="actors-modal scrollbar-small mt-3">
                {singleNetworkData?.map((network: SingleNetworkProps) => {
                    const ctype = getCtype(network?.ctype);
                    return (
                        <div key={network.docId}
                            className="p-2 rounded mb-2 bg-lightgray"
                        >
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <SourceIconLabel data={{ contentType: network.ctype }} className="d-inline" />
                                    <span className="text-primary cursor-pointer dont-break-out" onClick={() => goToActors?.(network.from)}>
                                        {getPostedByName(network.from)}
                                    </span>
                                    {' '}<span>via {ctype.name}</span>
                                    <p className="m-0"><a href={network.url} rel="noreferrer" target="_blank">{network.url}</a></p>
                                </div>
                                <DateVisualizer dateType={getCoordinatedDateType(newHeader)} startDate={network.pubdatetime} />
                            </div>
                            <NetworkText network={network} classnames="mt-2" />
                        </div>
                    ); }) }
            </div>
        </>
    ); };
