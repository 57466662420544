import { useLazyQuery } from '@apollo/client';
import React, { useMemo } from 'react';
import { Col, Row } from 'reactstrap';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BackButton } from '../../../../components/Atoms';
import { BreadCrumbs } from '../../../../components/BreadCrumbs';
import { GET_MULTIPLE_TRANSLATION } from '../../../../graphql';
import { getRoom } from '../../../../utils/variables';
import { ContentText } from './ContentText';
import { Entities } from './Entities';
import { RightPanel } from './RightPanel';
import { LabelsView } from './LabelsView';
import { Content } from '../../../../services/Content/contentInterface';
import { useQueryExtracts } from '../../../../services/Content/getQueryExtracts';
import { CONTENT_TYPE } from '../../../../utils/contentTypes';
import { KeywordsModal } from '../../../../components/ActivityItem/KeywordsModal';
import { useModal } from '../../../../context/modal/ModalComponent';
import { EngagementCards, EngagementDataType } from './ThreatPageCards/EngagementCards';
import { KeywordsCards } from './ThreatPageCards/KeywordCards';
import { WhyIsThisAThreat } from './WhyIsThisAThreat';
import { ActionButtons } from './ActionButtons';
import { formatThreatLabel } from '../../../../utils/text';
import { ThreatSignals } from './ThreatPageCards/ThreatSignals';
import { WarningAlert } from '../../../../components/Atoms/Alert/WarningAlert';
import { Coordination } from './ThreatPageCards/Coordination';

type RoomPreviewKeywords = {
    highlightKeywords: string[]
}

type ThreatPageProps = {
    data: Content;
};

type BreadType = {
    title: string;
    href?: string;
}

export const Page = ({
    data
}: ThreatPageProps) => {
    const { setModal } = useModal();
    const location = useLocation();
    const room = getRoom();
    const entities = formatSentimentData(data?.entitySentiment || []);
    const disabledThreatTypes = room.instance?.plan?.disabledThreatTypes;
    const contentCoordinationEnabled = !!room?.instance?.plan?.others?.contentCoordination;
    const contentType = (data?.threatObject?.threatLevel && data?.threatObject?.threatLevel !== 'none')
        ? CONTENT_TYPE.THREAT : CONTENT_TYPE.MATCH;
    const { highlightKeywords } = useSelector((state: {roomPreviewKeywords: RoomPreviewKeywords}) => state.roomPreviewKeywords);
    const passKeywords = location.pathname?.includes('create-room') || location.pathname?.includes('edit-room')
        || location.pathname?.includes('edit-draft-room');
    const displayThreatTable = data.threatObject?.threatLabels?.filter(label => label)
        ?.filter(label => !disabledThreatTypes?.includes(label))?.map(label => formatThreatLabel(label)?.name) || [];
    const { keywordsMentioned }: { keywordsMentioned: EngagementDataType[] } = useQueryExtracts({
        url: data.url,
        text: `${data.title} ... ${data.text}`,
        keywordsToHighlight: passKeywords ? highlightKeywords : [] as any
    });
    const openKeywordsModal = () => setModal({
        component: <KeywordsModal keywords={keywordsMentioned} />
    });
    const mostKeywordsMentioned = (keywordsMentioned as EngagementDataType[])?.sort(
        (current, next) => current.count - next.count
    ) || [];
    const topSixKeywordsMentioned = (mostKeywordsMentioned as EngagementDataType[]).slice(0, 6);

    const [translateEntities, { data: translatedEntities }] = useLazyQuery(GET_MULTIPLE_TRANSLATION, {
        variables: {
            data: entities.map(item => ({
                text: item.entity,
                target: 'en'
            }))
        },
    });

    const outputEntities = entities.map((item, i) => {
        if (translatedEntities) {
            return {
                ...item,
                original: item.entity,
                entity: translatedEntities.translateMultipleText[i].translations[0]
            };
        }
        return item;
    });

    const bread: BreadType[] = [
        { title: 'Situation Rooms', href: '/situation-rooms' },
        { title: `${room.project_name}`, href: `/situation-rooms/${room.id}` }
    ];

    if (location?.state?.from === 'aiNarratives') {
        bread.push({ title: 'AI Narratives', href: `/situation-rooms/${room.id}/ai-narratives` });
        bread.push({ title: 'Content Summary' });
    }
    else if (contentType === CONTENT_TYPE.THREAT) {
        bread.push({ title: 'Threats', href: `/situation-rooms/${room.id}/threats` });
        bread.push({ title: 'Content Summary' });
    }
    else {
        bread.push({ title: 'Matches', href: `/situation-rooms/${room.id}/overview/matches` });
        bread.push({ title: 'Content Summary' });
    }

    const engagementData = useMemo(() => {
        const likesCount = Number.isNaN(Number(data?.likesCount)) ? -1 : Number(data.likesCount);
        const retweetsCount = Number.isNaN(Number(data?.retweetsCount)) ? -1 : Number(data.retweetsCount);
        const repliesCount = Number.isNaN(Number(data?.repliesCount)) ? -1 : Number(data.repliesCount);
        const sharesCount = Number.isNaN(Number(data?.sharesCount)) ? -1 : Number(data.sharesCount);
        const viewsCount = Number.isNaN(Number(data?.viewsCount)) ? -1 : Number(data.viewsCount);
        const engagementsCount = Number.isNaN(Number(data?.engagement)) ? -1 : Number(data.engagement);

        if (!engagementsCount) {
            return [];
        }
        if (data?.contentType === 'tiktok') {
            if (data?.engagementType === 'COMMENT') {
                return [
                    { value: 'Hearts', count: likesCount },
                    { value: 'Replies', count: repliesCount },
                    { value: 'Estimated overall', count: engagementsCount }
                ];
            }
            return [
                { value: 'Hearts', count: likesCount },
                { value: 'Plays', count: viewsCount },
                { value: 'Shares', count: sharesCount },
                { value: 'Comments', count: repliesCount },
                { value: 'Estimated overall', count: engagementsCount }
            ];
        }
        return [
            { value: 'Likes', count: likesCount },
            { value: 'Reposts', count: retweetsCount },
            { value: 'Replies', count: repliesCount },
            { value: 'Shares', count: sharesCount },
            { value: 'Impressions', count: viewsCount },
            { value: 'Estimated overall', count: engagementsCount }
        ];
    }, [data]);
    const threatSignalsEnabled = !!room?.instance?.plan?.core?.threatSignals;

    const newEtlEnabled = room.instance?.plan?.others?.newEtl;

    return (
        <Col className="p-4 threat-page">
            <div className="mb-4">
                <BreadCrumbs items={bread} />
            </div>
            <BackButton gotToPreviousPath
                className="mb-4 p-0 d-block"
                pathName={`/situation-rooms/${room.id}/threats`}
            />
            <Row className="ml-0 mb-4">
                <Col xs="12" lg="10" xl="11" className="pr-1 pl-0">
                    <h1 className="m-0">Content summary</h1>
                </Col>
            </Row>
            {(!data.hasEnhancements && newEtlEnabled) && (
                <WarningAlert className="mb-4">
                    Sentiment, entities, and threat information will be available once data enhancement is completed for this content.
                </WarningAlert>
            )}
            <Row>
                <Col xs="12" lg="8" xl="9" className="pr-1 pb-4">
                    <ContentText data={data} translateEntities={translateEntities} />
                    <div className="bg-white p-2 mt-3 rounded-sm">
                        <EngagementCards engagementData={engagementData} />
                    </div>
                    <LabelsView contentId={data.id} />
                    {threatSignalsEnabled && <ThreatSignals content={data} isMatch={contentType !== CONTENT_TYPE.THREAT} />}
                    <div className="bg-white p-2 mt-3 rounded-sm">
                        <KeywordsCards title="Keyword mentions"
                            engagementData={topSixKeywordsMentioned}
                            onOtherKeywordsClick={openKeywordsModal}
                            total={keywordsMentioned.length}
                        />
                    </div>
                    {(contentType === CONTENT_TYPE.THREAT && displayThreatTable.length > 0 && !threatSignalsEnabled)
                     && <WhyIsThisAThreat data={data} />}
                    {contentCoordinationEnabled && <Coordination contentId={data.id} contentUrl={data?.url} />}
                    {!(threatSignalsEnabled && !(contentType === 'content_threat'))
                    && (
                        <div className="bg-white p-3 mt-3 rounded-lg">
                            <ActionButtons content={data} />
                        </div>
                    )}
                    <div className="bg-white p-3 mt-3 rounded-lg">
                        <Entities data={outputEntities} id={data.id} hasEnhancements={data.hasEnhancements} />
                    </div>
                </Col>
                <Col xs="12" lg="4" xl="3" className="pl-2 pb-4">
                    <RightPanel content={data} />
                </Col>
            </Row>
        </Col>
    );
};

const formatSentimentData = (data: string[]) => {
    const colours: any = {
        positive: 'success',
        negative: 'danger',
        neutral: 'warning',
    };
    return data.filter(a => a).map((item) => {
        const parts = item.split(':');
        const sentiment = parts.pop() || '';
        return ({
            entity: parts.join(':'),
            sentiment,
            color: colours[sentiment] || 'warning',
        });
    });
};
