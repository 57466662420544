import React from 'react';
import { UnexpectedError } from './Molecules/ErrorStates/UnexpectedError';
import { amplitudeTrack } from '../amplitude';

// This is pretty special react magic file. Allow to ignore some of the eslint rules

// eslint-disable-next-line no-restricted-syntax
export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        // eslint-disable-next-line react/no-unused-state
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // Log error details to Amplitude
        amplitudeTrack('Critical Error', {
            message: error.message,
            stack: error.stack,
            componentStack: errorInfo.componentStack,
        });
    }

    render() {
        const { state, props } = this;

        if (state?.hasError) {
            return (
                <UnexpectedError />
            );
        }

        return props.children;
    }
}
