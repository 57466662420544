import React, { useState } from 'react';
import { CustomInput } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { ClusterOrSubCluster } from '../../types';
import { SearchInput } from '../../../../../components/Molecules';
import { SearchSortDropdown } from '../../../../../components/Search/SearchSortDropdown';
import { Loading } from '../../../../../components/Loading';
import { useContent } from '../../../../../services/Content/getContent';
import { TooltipWrapper } from '../../../../../components/ToolTip/TooltipWrapper';
import { RootState } from '../../../../../store';
import { saveSelectedCluster } from '../../../store';
import { useGlobalFiltersInClusters } from '../../../../../services/Clusters/GlobalFIltersInClusters';
import { MatchesContentTable } from '../../../../../components/MatchesContentTable';
import { checkGrammer } from '../../../../../utils/text/checkGrammer';
import { EmptyState } from '../../../../../components/EmptyState';
import { Images } from '../../../../../utils/modeSwitch';
import { getRoom } from '../../../../../utils/variables';

const sortOptions = {
    dateNew: 'Newest first',
    dateOld: 'Oldest first',
    engagement: 'Engagement',
    score: 'Relevance'
};
type ClusterItem = {
    contentIds?: string[];
    [key: string]: any;
};

type ClusterContentProps<T extends boolean> = ClusterOrSubCluster<T>

export const ClusterContent = <T extends boolean>({
    cluster,
    isParent,
    loading: narrativesLoading,
    countLabel = true
}: ClusterContentProps<T>) => {
    const room = getRoom();
    const { hasDuplicatesFilter } = useSelector((state: RootState) => state.selectedCluster);
    const filters = useSelector((state: RootState) => state.clusterFilters.filters);
    const dispatch = useDispatch();
    const filterContentIds = [] as string[][];
    const collectContentIds = (
        filterItems: string[],
        clusterItems: ClusterItem[] | undefined,
        clusterKey: string
    ) => {
        filterItems.forEach((item) => {
            const contentIds = clusterItems?.find((a) => a[clusterKey] === item)?.contentIds;
            if (contentIds) filterContentIds.push(contentIds);
        });
    };
    if (filters?.keywords?.length) {
        collectContentIds(filters.keywords, cluster?.keyphrases, 'keyword');
    }
    if (filters?.from?.length) {
        collectContentIds(filters.from, cluster?.topActors, 'actor');
    }
    if (filters?.origin?.length) {
        collectContentIds(filters.origin, cluster?.locationMentions, 'location');
    }
    if (filters?.sources?.length) {
        collectContentIds(filters.sources, cluster?.topSources, 'source');
    }
    const isFilterSelected = () => {
        const dateCheck = _.isEqual({
            startDate: 0,
            endDate: 0,
        }, filters.dateRange);
        let noneSelected = true;

        const finalFilters = { sentiment: filters?.sentiment || [], languages: filters?.languages || [] };
        Object.entries(finalFilters).forEach(([key, value]) => {
            if (Array.isArray(value)) {
                if (value.length > 0) { noneSelected = false; }
            }
        });
        return (!noneSelected || (!dateCheck));
    };

    const checkIdFilters = (filters?.keywords?.length || filters?.sources?.length || filters?.origin?.length || filters?.from?.length);

    const [offset, setOffset] = useState<number>(0);
    const [sortOption, setSortOption] = useState(sortOptions.dateNew);
    const [sort, setSort] = useState('dateNew');
    const [searchText, setSearchText] = useState('');
    const contentIds = isParent
        ? cluster.subClusters.map(subCluster => subCluster.contentIds.map(content => content.id)).flat()
        : cluster.contentIds.map(content => content.id).flat();
    const limit = 10;
    const clusterContentData = isParent
        ? cluster.subClusters.map(subCluster => subCluster.contentIds).flat()
        : cluster.contentIds.flat();

    const handleSearch = (value: string) => {
        setSearchText(value);
    };
    const sortApplied = (sortNum: number) => {
        const sortKeys = Object.keys(sortOptions);
        setSort(sortKeys[sortNum - 1]);
        setOffset(0);
    };
    const handleSort = (num: number, e: React.MouseEvent<any>) => {
        sortApplied(num);
        setSortOption(e.currentTarget.name);
    };

    const combinedIds = isParent
        ? cluster.subClusters.map(subCluster => subCluster.contentIds.map(content => [content.id, ...content.duplicateIds]).flat()).flat()
        : cluster.contentIds.map(content => [content.id, ...content.duplicateIds]).flat();
    const finalIdsList = isFilterSelected() ? combinedIds : contentIds;
    const ContentIdsList = checkIdFilters ? _.intersection(...filterContentIds) : finalIdsList;

    const { filters: { languages, sentiment, date } } = useGlobalFiltersInClusters({ useGlobalFilters: true });

    const { loading: contentLoading, data: contents, count } = useContent({
        filters: {
            ids: (hasDuplicatesFilter && ((checkIdFilters === 0) && !isFilterSelected()))
                ? clusterContentData?.filter(clus => clus.duplicateIds.length > 0).map(x => x.id).flat() : ContentIdsList,
            ...(searchText ? { search: searchText } : {}),
            languages,
            sentiment,
            date
        },
        sort,
        limit,
        skip: offset,
        fetchPolicy: 'cache-and-network',
        name: 'getClusterContents'
    });
    const loading = contentLoading || narrativesLoading;
    return (
        <div className="bg-white p-2 selected-cluster-height scrollbar-small overflow-y-auto overflow-x-hidden">
            <div className="d-flex align-items-center mb-1">
                <SearchInput placeholder="Search by keywords" handleSearch={handleSearch} className="w-40 mr-3 flex-1" />
                <SearchSortDropdown score sortOption={sortOption} handleSort={handleSort} />
                {((checkIdFilters === 0) && !isFilterSelected()) && (
                    <TooltipWrapper id="select_duplicates"
                        tooltipText="Display only the content which have reposts.
                        Any pieces of content that do not have reposts will be hidden."
                        placement="top-start"
                        container="selected-cluster-view"
                        className="ml-1"
                    >
                        <div className="mb-1 d-flex set-min-width align-items-center">
                            <CustomInput type="checkbox"
                                checked={hasDuplicatesFilter}
                                id="cluster-duplicate-contents"
                                onChange={() => dispatch(saveSelectedCluster({ hasDuplicatesFilter: !hasDuplicatesFilter }))}
                            /> <span className="mt-11 mb-1">Only show content with reposts</span>
                        </div>
                    </TooltipWrapper>
                )}
            </div>
            {!loading && countLabel && (contents?.length && contents[0] !== null) ? (
                <p className="mb-3 mt-0">{count}{' '}
                    {checkGrammer('content', combinedIds.length > 1)}
                </p>
            ) : null}
            {(loading && !contents.length) && <Loading relative height={200} />}
            {!loading && (!contents?.length || contents[0] === null)
            && (
                <div className="border p-4">
                    <EmptyState title="No content available for this narrative theme"
                        icon={Images().noResults}
                        summary="The content of this Narrative has been removed & is no longer visible.
                    To see removed content, select ‘Removed content’ below, or find it in ‘Room settings’."
                        action={{
                            name: 'Removed content',
                            to: `/situation-rooms/${room.id}/settings?t=removedContent`,
                            color: 'secondary',
                        }}
                    />
                </div>
            )}
            {contents.length > 0 && contents[0] !== null && (
                <MatchesContentTable contents={contents}
                    pagination={{ offset, setOffset, count }}
                    total={count}
                    displayLimit={limit}
                    isPreview={false}
                    isAINarratives
                    clusterData={clusterContentData}
                />
            )}
        </div>
    );
};
