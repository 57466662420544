import React from 'react';
import { getRoom } from '../../../utils/variables';
import { BreadCrumbs } from '../../../components/BreadCrumbs';
import { NetworksTopBar } from './NetworksTopBar';
import { NetworksChart } from './NetworksChart';

export const CoordinatedNetworks = () => {
    const room = getRoom();
    return (
        <div>
            <div className="pt-4 px-4 pb-2 border-bottom">
                <BreadCrumbs items={[
                    { title: 'Situation Rooms', href: '/situation-rooms' },
                    { title: room.project_name, href: `/situation-rooms/${room.id}` },
                    { title: 'Coordinated networks' }
                ]}
                />
                <NetworksTopBar />
            </div>
            <div className="d-flex flex-column mh-0 networks-chart">
                <NetworksChart />
            </div>
        </div>
    );
};
