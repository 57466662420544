import { InternalRefetchQueriesInclude, useMutation } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
    GET_ALL_SUB_CLUSTERS,
    GET_CLUSTERS,
    GET_CLUSTERS_COUNT,
    GET_CLUSTERS_FOR_CHART,
    GET_CLUSTERS_FOR_LIST_VIEW,
    GET_SUB_CLUSTERS,
    GET_SUB_CLUSTERS_FOR_CHART,
    GET_SUB_CLUSTERS_FOR_LIST,
    GET_WATCHLIST,
    REMOVE_CONTENT,
    REMOVE_CONTENT_DJANGO,
    UPDATE_NARRATIVES_ON_REMOVE_CONTENT
} from '../../graphql';
import { useRemoveFromWatchlist } from '../../services/Threat/removeFromWatchlist';
import { getRoom, getUser } from '../../utils/variables';
import { useGetActiveQueries } from '../../services/getActiveQueries';
import { resetSelectedContent } from '../../pages/Room/store';
import { GET_NARRATIVE_THEME } from '../../pages/Room/AIClustering/Comparision/NarrativeThemes';

export const RemoveContentActions = {
    RemoveContent: 'remove_content',
    MarkAsThreat: 'mark_threat',
    MarkNotAsThreat: 'not_threat'
};

type UseRemoveContentProps = {
    onCompleted?: () => void
    type: string
    ids?: string[]
    action: string
    refetchQueries?: InternalRefetchQueriesInclude
    bulkRemove?: boolean
}

type ContentToRemove = {
    id: string
    type?: string
    markThreatInfo?: string[]
}

export const useRemoveContent = ({ onCompleted, type, ids, action, refetchQueries, bulkRemove }: UseRemoveContentProps) => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const room = getRoom();
    const user = getUser();
    const { removeFromWatchlist } = useRemoveFromWatchlist({
        situationRoomId: room.id
    } as any);

    const activeQueries = useGetActiveQueries([
        'getThreats',
        'getThreatSummary',
        'getMatchSummary',
        'getContentModalData',
        'getMatches',
        'getWatchlistContent'
    ]);
    const defaultRefetchQueries = [
        { query: GET_WATCHLIST, variables: { situationRoom: room.id } },
        ...activeQueries
    ];

    const [removeContentRequest, { loading, error }] = useMutation(
        (action === RemoveContentActions.RemoveContent) ? REMOVE_CONTENT : REMOVE_CONTENT_DJANGO,
        {
            onCompleted: () => {
                if (onCompleted) onCompleted();
                if (location.pathname.includes('watchlist')) {
                    removeFromWatchlist({
                        contentId: ids
                    });
                }
                dispatch(resetSelectedContent());
            },
            fetchPolicy: 'no-cache',
            ...(refetchQueries ? { refetchQueries } : { refetchQueries: defaultRefetchQueries }),
            awaitRefetchQueries: true
        }
    );

    const [updateNarrativesWithContent] = useMutation(UPDATE_NARRATIVES_ON_REMOVE_CONTENT, {
        onCompleted: () => {
            if (history.location.pathname.includes('ai-narratives')) {
                history.push(`/situation-rooms/${room.id}/ai-narratives`);
            }
        },
        fetchPolicy: 'no-cache',
        refetchQueries: [
            ...activeQueries, GET_NARRATIVE_THEME, GET_CLUSTERS_COUNT, GET_CLUSTERS, GET_CLUSTERS_FOR_LIST_VIEW, GET_CLUSTERS_FOR_CHART,
            GET_SUB_CLUSTERS, GET_ALL_SUB_CLUSTERS, GET_SUB_CLUSTERS_FOR_LIST, GET_SUB_CLUSTERS_FOR_CHART
        ],
        awaitRefetchQueries: true
    });

    const removeContent = (removeData: ContentToRemove[]) => {
        removeContentRequest({
            variables: {
                type,
                situationRoomId: room.id,
                projectId: room.project_id,
                data: removeData.map(item => ({
                    _id: item.id,
                    action,
                    created_by: user?.displayName || user?.firstName,
                    type,
                    role: user?.role,
                    mark_threat_info: item.markThreatInfo || []
                })),
                ...(bulkRemove ? { bulkRemove } : {})
            }
        });
        updateNarrativesWithContent({
            variables: {
                projectId: room.project_id,
                contentIds: removeData.map(item => item.id)
            }
        });
    };

    return {
        removeContent,
        loading,
        error
    };
};
