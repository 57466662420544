import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { DropdownWithMultiCheckbox } from '../components/DropdownWIthMultiCheckbox/index';
import { useSources } from '../../../services/Overview';
import { RootState } from '../../../store';
import { saveSelectedFilters } from '../../../pages/Room/store';

type SourcesFilterProps = {
    clusters: boolean,
    appliedFilters: any
}

export const SourcesFilter = ({ clusters = false, appliedFilters }: SourcesFilterProps) => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { contentType: selectedSources }: { contentType: string[] } = useSelector((state: RootState) => state.selectedFilters.filters);
    const { projectId } = location.state || {};
    const isThreatsPage = location.pathname?.includes('/threats') || location.pathname?.includes('/watchlist');
    const { data, loading } = useSources({ size: 50, useGlobalFilters: true, clusters, projectId, appliedFilters, isThreatsPage });
    const finalData = data?.length > 0 ? data : [];
    const sourceData = finalData.map((source: {key: string, source: string, count: number, contentCount?: number}) => ({
        id: source.key,
        name: source.source,
        selected: selectedSources.includes(source.key),
        count: (clusters ? source.contentCount : source.count) || 0
    })).sort((a: {count: number}, b: {count: number}) => b.count - a.count);

    const handleSelectItem = (ids: string[]) => {
        dispatch(saveSelectedFilters({
            contentType: ids
        }));
    };

    const countFilters = selectedSources.length;
    return (
        <div>
            <DropdownWithMultiCheckbox handleSelectItem={handleSelectItem}
                filterOptions={sourceData}
                value="sources"
                name={countFilters > 0
                    ? `${countFilters} option${countFilters > 1 ? 's...' : ''}` : 'Select options'}
                loading={loading}
                itemsPerPage={30}
            />
        </div>
    );
};
