import React from 'react';
import { Spinner } from 'reactstrap';
import { TranslateIconBlue } from '../../../assets/icons/svgicons';

type TranslateButtonProps = {
    onClick: (e: any) => void,
    className?: string,
    lang?: string | undefined,
    detectedLangs?: string[] | undefined,
    loading?: boolean,
    showText?: boolean
}

export const TranslateButton = ({ onClick, className, lang, detectedLangs, loading, showText }: TranslateButtonProps) => {
    if (!detectedLangs?.length && !lang) return null;
    if (detectedLangs?.length === 1 && detectedLangs.includes('en')) return null;
    if ((lang === 'English' && (detectedLangs?.length || 0) < 2)) return null;
    return (
        <div role="button"
            className={`position-relative ${className}`}
            onClick={onClick}
            data-testid="translate-button"
        >
            <div className={loading ? 'invisible' : ''}>
                <TranslateIconBlue size={20} />
                {showText && <span className="text-primary">Translate</span>}
            </div>
            {loading && (
                <div className="text-primary absolute-center">
                    <Spinner size="sm" />
                </div>
            )}
        </div>
    );
};
