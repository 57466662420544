import React from 'react';

type TranslatedFromLanguageProps = {
    languageVerbose?: string
    detectedLangs?: string[]
}

export const TranslatedFromLanguage = ({ languageVerbose, detectedLangs = [] }: TranslatedFromLanguageProps) => {
    const languages = [] as any[];
    const getLanguageNames = () => {
        if (detectedLangs?.length > 0) {
            detectedLangs.filter(lang => lang !== 'en').map(langs => {
                const languageDisplay = new Intl.DisplayNames(['en'], { type: 'language' });
                languages.push(languageDisplay.of(langs));
            });
        }
        else {
            languages.push(languageVerbose);
        }
        return `(Translated from ${languages.join(', ')}) - `;
    };

    return (
        <strong>{getLanguageNames()}</strong>
    ); };
