import { gql, useQuery } from '@apollo/client';
import { useContentFilters } from '../../../Room/useFilters';

export const usePreviewData = ({
    filters: defaultFilters = {},
    offset = 0,
    limit = 5,
    sort,
    skip,
    fetchPolicy
}) => {
    const filters = useContentFilters({ defaultFilters, useGlobalFilters: true });
    const response = useQuery(GET_PREVIEW_CONTENT, getQueryOptions({
        ...filters,
        limit,
        offset,
        sort,
        skip,
        fetchPolicy
    }));
    return getPreviewDataReturnedResponse({ response });
};

export const getPreviewData = async ({
    client,
    query,
    offset = 0,
    limit = 5,
    sort,
    skip,
    fetchPolicy,
    filters = {}
}) => {
    const response = await client.query({
        query: GET_PREVIEW_CONTENT,
        ...getQueryOptions({
            dslQuery: query,
            filters,
            limit,
            offset,
            sort,
            skip,
            fetchPolicy
        })
    });
    return getPreviewDataReturnedResponse({ response });
};

const getPreviewDataReturnedResponse = ({ response }) => ({
    ...response,
    data: response.data ? response.data.getPreviewContent.content : [],
    count: response.data ? response.data.getPreviewContent.total : 0
});

const getQueryOptions = ({ filters = {}, limit, offset, sort, skip, fetchPolicy, dslQuery }) => ({
    variables: {
        filters,
        dslQuery,
        limit,
        offset,
        sort
    },
    skip,
    fetchPolicy
});

export const GET_PREVIEW_CONTENT = gql`
    query getPreviewContent(
        $sort: ContentSort
        $limit: Int = 10
        $offset: Int = 0
        $filters: ContentFilters
        $dslQuery: JSON
    ) {
        getPreviewContent(
            sort: $sort
            limit: $limit
            offset: $offset
            filters: $filters
            dslQuery: $dslQuery
        ) {
            total
            content {
                id
                body
                contentType
                date
                datetime
                detectedLangs
                displayName
                engagement
                engagementType
                entities
                entitySentiment
                extractedUrls
                from
                hashtags
                image
                isThreat
                isToxic
                keywords
                lang
                langVerbose
                likesCount
                locationsDetected
                mentions
                pubdatetime
                repliesCount
                retweetsCount
                sentiment
                sharesCount
                sourceCredibility
                text
                threadId
                threatLevel
                threatObject {
                    threatLevel
                    threatLabels
                    threatResolvers {
                        type
                        resolver
                        confidence
                    }
                }
                uid
                url
                video
                viewsCount
                whyathreat
            }
        }
    }
`;
