import React, { useRef } from 'react';
import { Button } from 'reactstrap';
import { TooltipItem } from '../../../components/ToolTip';
import { SearchBarNew, SearchBarNewRef } from '../../../components/NewFilters';

export const NetworksTopBar = () => {
    const searchRef = useRef<SearchBarNewRef>(null);
    return (
        <>
            <div className="d-flex align-items-center">
                <div>
                    <h3 className="m-0">
                        Coordinated networks
                        <TooltipItem id="networksTooltip"
                            placement="bottom-start"
                            content={(
                                <div>
                                    <p>
                                        Common links between posts or comments are found as more data is ingested to the Situation Room
                                        over time. You can inspect the links and the shared themes here, visualizing the networks in an
                                        interactive format.
                                    </p>
                                    <a href="https://logically-intelligence.readme.io/docs/networks"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <Button color="link" className="p-0">Read more</Button>
                                    </a>
                                </div>
                            )}
                        />
                    </h3>
                </div>
            </div>
            <div className="mt-3">
                <SearchBarNew sort={false} showBooleanSearch ref={searchRef} />
            </div>
        </>
    );
};
