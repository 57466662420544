import React from 'react';
import { useDispatch } from 'react-redux';
import { useModal } from '../../context/modal/ModalComponent';
import { LinkModal } from '.';
import { saveFilters } from '../../pages/Room/store';

type ExternalLinkButtonProps = {
    url: string;
    children?: React.ReactNode;
    classname?: string
    overview?: boolean
}

export const ExternalLinkButton = ({ url, children, classname = '', overview = false }: ExternalLinkButtonProps) => {
    const { setModal } = useModal();
    const dispatch = useDispatch();
    const handleOnClick = () => (
        <p color="link"
            data-testid={`table-filter-${url}`}
            className="text-primary p-0 m-0 cursor-pointer"
            onClick={() => {
                dispatch(saveFilters({ urls: [url] }));
            }}
        >{url}
        </p>
    );

    return (
        <div>
            {overview ? handleOnClick() : (
                <a href={url}
                    target="_blank"
                    rel="noreferrer"
                    className={`dont-break-out url-hover ${classname}`}
                    data-testid={`table-link-${url}`}
                    onClick={(e) => {
                        if (!localStorage.getItem('externalLinks')) {
                            e.preventDefault();
                            setModal({
                                header: false,
                                component: <LinkModal link={url} />
                            });
                        }
                    }}
                >
                    {children || url}
                </a>
            )}
        </div>
    );
};
